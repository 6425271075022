// @import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');

$theme-colors: (
  "info": #00a79d
);
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./components/style/summary.scss";

body {
  font-family: "Segoe UI", sans-serif;
}

.page-monthly {
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    display: none;
  }

  .react-datepicker__navigation {
    display: none;
  }
}

.page-weekly {
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    display: none;
  }

  .react-datepicker__navigation {
    display: none;
  }
}

.year-picker {
  .picker-panel {
    z-index: 100;
  }
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container input {
  border: 1px solid #ddd;
  padding: 7px 15px;
  border-radius: 4px;
  width: 100%;
}

.year-picker {
  float: right;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.nav.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;

  .nav-link {
    white-space: nowrap;
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #fff #fff #868686;
  border-radius: 0;
  border-left: 0;
  border-right: 0;

  &:hover {
    border-bottom-color: #868686;
  }
}

.nav-tabs .nav-link {
  border: 3px solid transparent;
  border-radius: 0;
  color: #444;
  padding-left: 0;
  padding-right: 0;
  margin-right: 30px;
  border-left: 0;
  border-right: 0;

  &:hover {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    color: #868686;
  }
}

.tab-content {
  padding: 20px 0;
}

button.gm-ui-hover-effect {
  display: none !important;
}

.custom-datepicker {
  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container {
    display: block;
  }

  input {
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #cbcbcb;
  }
}

.custom-datepicker.full {
  input {
    width: 100%;
  }
}
