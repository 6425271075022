.navside-ew431 {
  ul.parent {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      text-transform: capitalize;

      a,
      span {
        display: block;
        // border: 1px solid black;
        padding: 10px 20px;
        color: #79829c;
        font-size: small;
        font-weight: 500;

        &:hover {
          color: #f4f4f4;
          text-decoration: none;
        }
      }

      a {
        &.active {
          color: #f4f4f4;
        }
      }
    }

    li.have-children {
      position: relative;
      cursor: pointer;

      img.arrow-down {
        height: 20px;
        position: absolute;
        right: 15px;
        top: 13px;
        // transition: 0.5s transform;
      }

      ul.children {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 0;
        overflow-y: hidden;

        li {
          a {
            padding-left: 45px;
          }
        }
      }

      &.show {
        ul.children {
          height: auto;
        }

        img.arrow-down {
          transform: rotate(-180deg);
        }
      }

    }
  }
}