// @import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html,
body {
  font-size: 15px;
  background-color: #f8f9fa;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
}

.sidenav {
  height: 100%;
  position: fixed;
  z-index: 10;
  background-color: #2a3042;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  width: 200px;

  @media (max-width: 991.98px) {
    margin-left: -200px;
    transition: 0.5s margin;
  }
}

.main-page {
  margin-left: 200px;
  padding-bottom: 30px;

  @media (max-width: 991.98px) {
    margin-left: 0;
  }
}

// global
body {}

.table {
  th {
    font-size: 0.8rem;
    font-weight: bold;
    color: grey;
  }

  &.table-v-middle {
    tr {
      td {
        vertical-align: middle !important;
      }
    }
  }
}

.sub-menu-content {
  .nav-link {
    color: gray;

    &.active {
      color: #48bb78;
      font-weight: bold;
    }
  }
}

.messageList {
  color: black;
  background: white;
  border: 1px solid #edecec;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    color: black;
    background: lighten(#48bb78, 35%);
    border-color: lighten(#48bb78, 32%) !important;
  }

  .badge-number {
    background: red;
    color: white;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    display: inline-block;
    text-align: center;
    font-size: 0.8rem;
  }
}



.summary-list {
  background: url('../../components/img/bg-chat.svg') no-repeat right 10px top 15px;
  background-size: 76px;
}

.main-content-front {
  padding-top: 130px;
  padding-bottom: 100px;
}

.backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}

.drop-shadow {
  box-shadow: 0px 0px 20px #f2f2f2;
}

.table-default {
  background-color: #eff2f7;
  border-bottom: 0;
}

.table-default th {
  color: #495057 !important;
}

tr td {
  color: #495057 !important;
}

.modal-open .modal {
  z-index: 1050;
}

.rounded-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #556ee6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-icon-mini {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #556ee6;
  display: flex;
  justify-content: center;
  align-items: center;
}