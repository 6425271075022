.summary-tgdwy {
  .text-muted {
    text-transform: capitalize;
  }

  .icon-frame {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    margin-top: 8px;

    i {
      font-size: 20px;
    }
  }
}

.summarynew-kslaklm {
  padding: 0.4rem;

  .icon-frame {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    margin-top: 8px;

    i {
      font-size: 20px;
    }
  }

  .label {}

  .price.main {
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 28px;
  }

  .sum-ket {
    margin-top: 2px;
  }
}