.mynavbar {
  margin-left: 200px;
  transition: 0.5s margin;
  z-index: 10;
  box-shadow: 0px 5px 20px #eee;

  &.fixed-top {
    z-index: 4;
  }

  @media (max-width: 991.98px) {
    margin-left: 0;
  }
}